import React from "react";
import ItemCard from "../../../components/ItemCard";
import { Col, Row } from "react-bootstrap";
import DummyData from "../../../data/items.json";
import Header from "../../../components/Header";

function ListingPage() {
  return (
    <>
      <Header headerTitle="Items" />
      <Row xs={1} md={2} lg={3} className="g-3">
        {DummyData.map((item) => (
          <Col key={item.ID}>
            <ItemCard {...item} />
          </Col>
        ))}
      </Row>
    </>
  );
}

export default ListingPage;
