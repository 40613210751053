import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import React, { ReactPropTypes } from "react";

function Header(props: { headerTitle: string }) {
  return (
    <div className="header">
      <h1>{props.headerTitle}</h1>
      <FontAwesomeIcon icon={regular("user")} />
    </div>
  );
}

export default Header;
