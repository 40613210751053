import React from "react";
import logo from "./logo.svg";
import { Counter } from "./features/counter/Counter";
import "./App.scss";
import { Routes, Route, useParams } from "react-router-dom";
import Home from "./features/Home/Home";
import ImportExistingItems from "./features/ImportExistingItems/ImportExistingItems";
import ImportSupplierFile from "./features/ImportSupplierFile/ImportSupplierFile";
import ListingPage from "./features/ItemList/ListingPage/ListingPage";
import DetailsPage from "./features/ItemList/DetailsPage/DetailsPage";
import Header from "./components/Header";
import NotFound from "./features/NotFound/NotFound";
import SignIn from "./features/Home/SignIn";
import store from "./store";

store.setState("isSignedIn", false); // Create "count" global state and add it to the store

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/ImportExistingItems" element={<ImportExistingItems />} />
      <Route path="/ImportSupplierFile" element={<ImportSupplierFile />} />
      <Route path="/ViewItemList" element={<ListingPage />} />
      <Route path="/DetailsPage/:sourcingID" element={<DetailsPage />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
