import React, { useEffect } from "react";
import ImportExistingItems from "../ImportExistingItems/ImportExistingItems";
import ImportSupplierFile from "../ImportSupplierFile/ImportSupplierFile";
import Header from "../../components/Header";
import ListingPage from "../ItemList/ListingPage/ListingPage";
import HomeButton from "../../components/HomeButton";
import store from "../../store";
import SignIn from "./SignIn";

function Home() {
  const renderHomeButtons = () => {
    const menuList = [
      "Import Supplier File",
      "Import Existing Items",
      "View Item List",
    ];
    return menuList.map((item) => (
      <HomeButton ButtonText={item} LinkTo={item.replaceAll(" ", "")} />
    ));
  };

  const renderHomePage = () => {
    switch (sessionStorage.getItem("issignedin") || "0") {
      case "1":
        return (
          <>
            <Header headerTitle="Toymate Sourcing" />
            <div className="default-alignment">{renderHomeButtons()}</div>
          </>
        );
      case "0":
        return <SignIn />;
    }
  };

  return <>{renderHomePage()}</>;
}

export default Home;
