import React from "react";
import Button from "react-bootstrap/Button";

function ImportSupplierFile() {
  return (
    <>
      <div className="home-button-list">
        <Button variant="primary">Import Existing Items</Button>{" "}
      </div>
    </>
  );
}

export default ImportSupplierFile;
