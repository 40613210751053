import React from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

type HomeButtonProps = {
  ButtonText: string;
  LinkTo: string;
};

function HomeButton({ ButtonText, LinkTo }: HomeButtonProps) {
  return (
    <>
      <div className="home-button-list">
        <Link to={LinkTo}>
          <Button variant="primary">{ButtonText}</Button>{" "}
        </Link>
      </div>
    </>
  );
}

export default HomeButton;
