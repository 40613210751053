import React from "react";
import Header from "../../../components/Header";

function DetailsPage() {
  return (
    <>
      <Header headerTitle="Item Detail" />
      <div>DetailsPage</div>;
    </>
  );
}

export default DetailsPage;
