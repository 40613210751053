import React from "react";
import Header from "../../components/Header";

function NotFound() {
  return (
    <>
      <Header headerTitle="Not Found" />
    </>
  );
}

export default NotFound;
