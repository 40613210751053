// import { trackPromise } from 'react-promise-tracker';
// export const spURL_GET = "http://localhost:53064/api/values/Get"
// export const spURL_POST = "http://localhost:53064/api/values/Post"

export const spURL_GET = "https://tmapi-sourcing.azurewebsites.net/api/values/Get/"
export const spURL_POST = "https://tmapi-sourcing.azurewebsites.net/api/values/Post/"

export const getReqOpt_GET = () => {
    return {
        method: 'GET'
    }
}

export const getReqOpt_POST = (inputJSON: string) => {
    return {
        method: 'POST',
        body: new URLSearchParams({
            inputJSON: inputJSON
        })
    }
}

export async function runFetch(url: string, reqOpt: RequestInit) {
    try {
        const resURL: URL = new URL(url);

        // const res = await trackPromise(fetch(url, reqOpt));
        const res = await fetch(resURL, reqOpt);
        return res
            .json()
            .then(data => {
                return data;
            })
            .catch(err => {
                return JSON.stringify({
                    status: 'ERROR!',
                    exception: `ERROR!: ${err.message}`,
                    response: ''
                });
            });
    } catch (err) {
        return JSON.stringify({
            status: 'ERROR!',
            exception: `ERROR!: Server error. Please try again later.`,
            response: ''
        });
    }
}