import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";

type ItemCardProps = {
  ID: string;
  ItemNo: string;
  Description: string;
  Cost: number;
  ImgUrl: string;
  ImgBlob: string;
  Buyer: string;
};

function ItemCard({
  ID,
  ItemNo,
  Description,
  Cost,
  ImgUrl,
  ImgBlob,
  Buyer,
}: ItemCardProps) {
  const urlResolve = (imgUrl: string, imgBlob: string) => {
    if (imgUrl !== "") {
      return imgUrl;
    } else {
      // var base64EncodedStr = btoa(unescape(encodeURIComponent(imgBlob)));
      // imgBlob = "'data:image/jpeg;base64," + base64EncodedStr;

      // const blob = new Blob([imgBlob], { type: "image/png" }); // Create a BLOB object
      // return URL.createObjectURL(blob);

      return `data:image/jpeg;base64,${imgBlob}`;
    }
  };

  return (
    <Card>
      <Card.Img
        variant="top"
        src={urlResolve(ImgUrl, ImgBlob)}
        height="200px"
        style={{ objectFit: "contain" }}
      />
      <Card.Body className="d-flex flex-column">
        <Card.Title className="d-flex justify-content-between align-items-baseline mb-4">
          <span className="fs-2">{Description}</span>
          <span className="fs-4">{ItemNo}</span>
        </Card.Title>
      </Card.Body>
    </Card>
  );
}

export default ItemCard;
